import React from "react";
import NewsArticleLayout from "../../../components/updated-article/NewsArticleLayout.component";

const isNew = true;
const href = "/ratesheet/20241113-Rate-Sheet.pdf";
const date = "13.11.2024";

const Article = () => (
  <NewsArticleLayout href={href} date={date} isNew={isNew}>
    <h1 className="page-title">Changes to our mortgage products</h1>
    <p>With effect from Wednesday 13 November 2024, we will be making the following changes to our range:</p>
    <p>
      <strong>Summary of changes</strong>
    </p>
    <p>
      <strong>UK Residential Existing Customer Switching Range</strong>
    </p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have increased</li>
      <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>3 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have increased</li>
      <li>3 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have increased</li>
    </ul>
    <ul>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% have increased</li>
      <li>10 Year Fixed Fee Saver at 60%, 70%, 75% and 80% LTV have increased</li>
      <li>10 Year Fixed Standard at 60%, 70%, 75% and 80% have increased</li>
    </ul>
    <p>
      <strong>UK Residential Existing Customer Borrowing More Range</strong>
    </p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>3 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>3 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% have increased</li>
      <li>10 Year Fixed Fee Saver at 60%, 70%, 75% and 80% LTV have increased</li>
      <li>10 Year Fixed Standard at 60%, 70%, 75% and 80% have increased</li>
    </ul>
    <p>
      <strong>UK Residential First Time Buyer Range</strong>
    </p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have increased</li>
      <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>2 Year Fixed High Value Mortgages at 60%, 70% and 75% LTV have increased</li>
      <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have increased</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>5 Year Fixed High Value Mortgages at 60%, 70% and 75% LTV have increased</li>
      <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
    </ul>
    <p>
      <strong>UK Residential First Time Buyer Energy Efficient Home Range (A &amp; B EPC Rated Properties)</strong>
    </p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have increased</li>
      <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have increased</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
    </ul>
    <p>
      <strong>UK Residential Home Mover Range</strong>
    </p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have increased</li>
      <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>2 Year Fixed High Value Mortgages at 60%, 70% and 75% LTV have increased</li>
      <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have increased</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>5 Year Fixed High Value Mortgages at 60%, 70% and 75% LTV have increased</li>
      <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
    </ul>
    <p>
      <strong>UK Residential Home Mover Energy Efficient Home Range (A &amp; B EPC Rated Properties)</strong>
    </p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have increased</li>
      <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have increased</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
    </ul>
    <p>
      <strong>UK Residential Remortgage Range</strong>
    </p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>2 Year Fixed High Value Mortgages at 60%, 70% and 75% LTV have increased</li>
      <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>5 Year Fixed High Value Mortgages at 60%, 70% and 75% LTV have increased</li>
      <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
    </ul>
    <p>
      <strong>UK Residential Remortgage Energy Efficient Home Range (A &amp; B EPC Rated Properties)</strong>
    </p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
    </ul>
    <p>
      <strong>UK Residential Remortgage Cashback Range</strong>
    </p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
    </ul>
    <p>
      <strong>UK Buy to Let Existing Customer Switching Range</strong>
    </p>
    <ul>
      <li>2 Year Fixed Standard at 60%, 65% and 75% LTV have increased</li>
      <li>5 Year Fixed Fee Saver at 60%, 65% and 75% LTV have increased</li>
      <li>5 Year Fixed Standard at 60%, 65% and 75% LTV have increased</li>
    </ul>
    <p>
      <strong>UK Buy to Let Existing Customer Borrowing More Range</strong>
    </p>
    <ul>
      <li>2 Year Fixed Standard at 60%, 65% and 75% LTV have increased</li>
      <li>5 Year Fixed Fee Saver at 60%, 65% and 75% LTV have increased</li>
      <li>5 Year Fixed Standard at 60%, 65% and 75% LTV have increased</li>
    </ul>
    <p>
      <strong>UK Buy to Let Purchase Range</strong>
    </p>
    <ul>
      <li>5 Year Fixed Fee Saver at 60%, 65% and 75% LTV have increased</li>
      <li>5 year Fixed &pound;1999 Standard at 60%, 65% and 75% LTV have increased</li>
      <li>5 year Fixed &pound;3999 Standard at 60%, 65% and 75% LTV have increased</li>
    </ul>
    <p>
      <strong>UK Buy to Let Remortgage Range</strong>
    </p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 65% and 75% LTV have increased</li>
      <li>2 year Fixed &pound;1999 Standard at 60%, 65% and 75% LTV have increased</li>
      <li>2 year Fixed &pound;3999 Standard at 60%, 65% and 75% LTV have increased</li>
      <li>5 Year Fixed Fee Saver at 60%, 65% and 75% LTV have increased</li>
      <li>5 year Fixed &pound;1999 Standard at 60%, 65% and 75% LTV have increased</li>
      <li>5 year Fixed &pound;3999 Standard at 60%, 65% and 75% LTV have increased</li>
    </ul>
    <p>
      <strong>International Residential Range</strong>
    </p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70% and 75% LTV have increased</li>
      <li>2 Year Fixed Standard at 60%, 70% and 75% LTV have increased</li>
      <li>3 Year Fixed Fee Saver at 60%, 70% and 75% LTV have increased</li>
      <li>3 Year Fixed Standard at 60%, 70% and 75% LTV have increased</li>
      <li>5 Year Fixed Fee Saver at 60%, 70% and 75% LTV have increased</li>
      <li>5 Year Fixed Standard at 60%, 70% and 75% LTV have increased</li>
      <li>10 Year Fixed Fee Saver at 60%, 70% and 75% LTV have increased</li>
      <li>10 Year Fixed Standard at 60%, 70% and 75% LTV have increased</li>
      <li>5 Year Fixed Premier Exclusive at 60%, 70% and 75% LTV have increased</li>
    </ul>
    <p>
      <strong>International Buy to Let Range</strong>
    </p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60% and 65% LTV have increased</li>
      <li>2 Year Fixed Standard at 60%, 65% and 75% LTV have increased</li>
      <li>5 Year Fixed Fee Saver at 60%, 65% and 75% LTV have increased</li>
      <li>5 Year Fixed Standard at 60%, 65% and 75% LTV have increased</li>
    </ul>
    <p>There are no other changes to any other interest rates.</p>
    <p>An updated mortgage rate sheet will be published on Wednesday 13 November 2024 to reflect these changes.</p>
  </NewsArticleLayout>
);

export default Article;
